"use client";
import React from "react";
import dynamic from "next/dynamic";
import { Loader } from "modules/common/components/Loader";
import { MapProps } from "modules/common/components/map/Map";

const DynamicFindStoresMap = ({ heightSVH }: MapProps) => {
  const Map = React.useMemo(
    () =>
      dynamic(() => import("modules/common/components/map/Map"), {
        loading: () => <Loader />,
        ssr: false,
      }),
    [
      /* list variables which should trigger a re-render here */
    ]
  );
  return <Map heightSVH={heightSVH} />;
};

export default DynamicFindStoresMap;
